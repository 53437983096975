/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    /*{
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    },*/
    {
        id   : 'summary',
        title: 'Summary',
        type : 'basic',
        icon : 'heroicons_outline:desktop-computer',
        link : '/summary'
    },
    {
        id   : 'users',
        title: 'Users',
        type : 'basic',
        icon : 'feather:users',
        link : '/users'
    },
    {
        id   : 'assets',
        title: 'Assets',
        type : 'basic',
        icon : 'devices',
        link : '/assets'
    },
    {
        id   : 'inventory',
        title: 'Inventory',
        type : 'basic',
        icon : 'inventory',
        link : '/inventory'
    },
    {
        id   : 'corrective',
        title: 'Corrective Maintaince',
        type : 'basic',
        icon : 'report_problem',
        link : '/corrective'
    },
    {
        id   : 'preventive',
        title: 'Preventive Maintenance',
        type : 'basic',
        icon : 'schedule',
        link : '/preventive'
    },
    {
        id   : 'manage',
        title: 'Add/Manage',
        type : 'basic',
        icon : 'heroicons_outline:document-add',
        link : '/masters'
    },
    // {
    //     id   : 'training',
    //     title: 'Training',
    //     type : 'basic',
    //     icon : 'model_training',
    //     link : '/training'
    // },{
    //     id   : 'incidents',
    //     title: 'Incidents',
    //     type : 'basic',
    //     icon : 'report_problem',
    //     link : '/incidents'
    // },{
    //     id   : 'lost_found',
    //     title: 'Lost & Found',
    //     type : 'basic',
    //     icon : 'emoji_objects',
    //     link : '/lost-found'
    // },{
    //     id   : 'key',
    //     title: 'Key Management',
    //     type : 'basic',
    //     icon : 'feather:key',
    //     link : '/key-management'
    // },
    // {
    //     id   : 'handover',
    //     title: 'Handover/Takeover',
    //     type : 'basic',
    //     icon : 'assignment_ind',
    //     link : '/handover'
    // },
    // {
    //     id   : 'stadiums',
    //     title: 'Stadiums',
    //     type : 'basic',
    //     icon : 'games',
    //     link : '/stadiums'
    // }
    
];
export const compactNavigation: FuseNavigationItem[] = defaultNavigation;
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation;
