import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import {ToastrModule} from "ngx-toastr";
import {DragDropModule} from "@angular/cdk/drag-drop";
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { CustomErrorHandlerService } from "./_services/customErrorHandler.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpInterceptorService } from "./_services/http-interceptor.service";
import { ImageCropperModule } from 'ngx-image-cropper';
import { MaterialModule } from "./material.module";
const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        ImageCropperModule,
        BrowserModule,
        MaterialModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        ToastrModule.forRoot({
          timeOut: 8000, progressBar: true, enableHtml: true,
          closeButton: true, progressAnimation: 'increasing',
          preventDuplicates: true,
        }),
        DragDropModule,
        // Layout module of your application
        // LayoutModule
    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
        {provide: ErrorHandler, useClass: CustomErrorHandlerService},
        {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true }
    ],
})
export class AppModule
{
}
